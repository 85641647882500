.card {
    --border-opacity: 1;
    padding: 1.25rem;
    border: 1px solid rgb(30 41 59/var(--border-opacity));
    justify-content: space-between;
    background-color: transparent;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s;
    width: 100%;
    display: flex;
    flex-direction: column;

    &:hover {
        border: 1px solid rgb(71 85 105/var(--border-opacity));
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
    }

    &__top {
        &--author {
            --text-opacity: 1;
            color: rgb(100 116 139/var(--text-opacity));
            font-size: .875rem;
            line-height: 1.25rem;

            .name {
                --text-opacity: 1;
                color: rgb(148 163 184/var(--text-opacity));
                font-weight: 500;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 150px;
            }
        }

        &--view {
            .btn {
                --text-opacity: 1;
                --bg-opacity: 0.4;
                --bg-opacity: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.5rem;
                color: rgb(148 163 184/var(--text-opacity));
                background-color: rgb(0 0 0/var(--bg-opacity));
                border-radius: 9999px;
                width: 2.5rem;
                height: 2.5rem;

                &:hover {
                    --text-opacity: 1;
                    color: rgb(255 255 255/var(--text-opacity));
                }
            }
        }
    }

    &__body {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }

    &__footer {
        &--name {
            --text-opacity: 1;
            max-width: 150px;
            font-size: .875rem;
            line-height: 1.25rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: rgb(255 255 255/var(--text-opacity));
            margin-bottom: 0;
        }

        &--button {
            .btn {
                transition-property: all;
                transition-timing-function: cubic-bezier(.4, 0, .2, 1);
                transition-duration: .15s;
                --text-opacity: 1;
                color: rgb(100 116 139/var(--text-opacity));
                font-weight: 500;
                font-size: .875rem;
                line-height: 1.25rem;
                padding-left: 0.75rem;
                padding-right: 0.75rem;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                border-radius: 0.5rem;

                svg {
                    margin-right: 7px;
                }

                &.btn--orange:hover {
                    --text-opacity: 1;
                    color: rgb(255 255 255/var(--text-opacity));
                    --bg-opacity: 1;
                    background-color: rgb(249 115 22/var(--bg-opacity));
                }

                &.btn--blue:hover {
                    --text-opacity: 1;
                    color: rgb(255 255 255/var(--text-opacity));
                    --bg-opacity: 1;
                    background-color: rgb(59 130 246/var(--bg-opacity));
                }
            }
        }
    }
}

@media (min-width:991px) {
    .card {
        width: calc(100% / 3 - 1.5rem);
    }
}