.form-login {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    max-width: 42rem;
    margin-left: auto;
    margin-right: auto;

}

.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.75rem;
    flex: 1 1 0%;
    margin-bottom: 2.5rem;

    label {
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
        cursor: pointer;
    }

    .wrap-input {
        position: relative;
        width: 100%;

        input {
            --text-opacity: 1;
            --bg-opacity: 1;
            --border-opacity: 1;
            font-size: 1rem;
            line-height: 1.5rem;
            color: rgb(255 255 255/var(--text-opacity));
            padding: 1rem;
            outline: 2px solid transparent;
            outline-offset: 2px;
            background-color: rgb(15 23 42/var(--bg-opacity));
            border: 1px solid rgb(51 65 85/var(--border-opacity));
            border-radius: 0.5rem;
            width: 100%;

            &:focus {
                --border-opacity: 1;
                border-color: rgb(59 130 246/var(--border-opacity));
            }
        }

        svg {
            position: absolute;
            top: 50%;
            right: 1rem;
            cursor: pointer;
            --text-opacity: 1;
            color: rgb(156 163 175/var(--text-opacity));
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            width: 2rem;
        }
    }
}