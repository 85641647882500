.sidebar {
    .logo-wraper {
        margin-top: 1.25rem;
        margin-bottom: 2.5rem;
        font-size: .875rem;
        line-height: 1.25rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-weight: 700;
        display: flex;
        align-items: center;
        color: #fff;
        text-decoration: none;

        .logo {
            margin-right: 15px;
            max-width: 25px;
        }
    }

    &__ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;

        &__li {
            a {
                color: #fff;
                text-decoration: none;
                padding-top: 0.75rem;
                padding-bottom: 0.75rem;
                padding-left: 1rem;
                padding-right: 1rem;
                border-radius: 0.5rem;
                display: flex;
                align-items: center;
                -webkit-column-gap: 0.75rem;
                column-gap: 0.75rem;

                .icon svg {
                    width: 1.25rem;
                    height: 1.25rem;
                }

                &:hover,
                &.active {
                    --bg-opacity: 1;
                    background-color: rgb(31 41 55/var(--bg-opacity));
                }
            }
        }
    }

    &__btn {
        position: absolute;
        bottom: 0;

        .btn-logout {
            --bg-opacity: 1;
            background-color: rgb(30 41 59/var(--bg-opacity));
            margin-bottom: 30px;

            .icon {
                margin-right: 0.75rem;

                svg {
                    width: 1.5rem;
                }
            }
        }


    }
}