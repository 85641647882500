.header {
    &--wrapper {
        --border-opacity: 1;
        padding: 1.25rem;
        border-bottom: 1px solid rgb(30 41 59/var(--border-opacity));
    }

    &__info {
        display: flex;
        align-items: center;
        text-decoration: none;

        &--avatar {
            width: 2.5rem;
            height: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 9999px;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 1.25rem;
            line-height: 1.75rem;
            --border-opacity: 1;
            border-color: rgb(100 116 139/var(--border-opacity));
            color: #fff;
            margin-right: 15px;
        }

        &--name {
            color: #fff;
            margin-bottom: 0;
        }
    }

    .logo {
        color: rgb(255 255 255/var(--text-opacity));
        text-decoration: none;
        display: flex;
        align-items: center;

        img {
            width: 20px;
            height: auto;
        }

        span {
            font-weight: 600;
            margin-left: 1rem;
        }
    }
}

@media (max-width:375px) {
    .header {
        &--wrapper {
            padding: 0.5rem;
        }
    }
}

.bg-gradient-secondary {
    background-image: linear-gradient(to right top, #fc6c8f, #ffb86c);
}

.bg-gradient-primary {
    background-image: linear-gradient(to right top, #6a5af9, #d66efd);
}

.pointer-effect {
    -webkit-animation: pointer 1s cubic-bezier(.075, .82, .165, 1) infinite;
    animation: pointer 1s cubic-bezier(.075, .82, .165, 1) infinite;
}

@-webkit-keyframes pointer {
    to {
        -webkit-transform: scale(.75);
        transform: scale(.75)
    }
}

@keyframes pointer {
    to {
        -webkit-transform: scale(.75);
        transform: scale(.75)
    }
}

.btn {
    --text-opacity: 1;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-radius: 0.5rem;
    color: rgb(255 255 255/var(--text-opacity));
    position: relative;
    font-weight: 500;
}

.btn+.btn {
    margin-left: 1rem;
}

.btn-effect {
    position: relative;
    isolation: isolate;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: auto;
        top: 0;
        height: 100%;
        width: 0;
        background-color: #fff;
        opacity: .1;
        z-index: -1;
        transition: all .4s cubic-bezier(.165, .84, .44, 1);
    }

    &:hover::before {
        left: auto;
        right: 0;
        width: 100%;
    }
}