.layout__dashboard {
    display: flex;
    min-height: 100vh;

    #colunm-left {
        padding: 1.25rem;
        width: 300px;
        --border-opacity: 1;
        border-right: 1px solid rgb(30 41 59/var(--border-opacity));
    }

    #content {
        width: calc(100% - 300px);
        padding: 2.5rem;
    }
}