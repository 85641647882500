.footer {
    padding: 1.5rem 0;

    .copyright {
        a {
            --text-opacity: 1;
            padding-left: 5px;
            color: rgb(255 255 255/var(--text-opacity));
            text-decoration: none;

            &:hover {
                opacity: .7;
            }
        }
    }
}