.section-banner {
    padding-top: 5rem;
    padding-bottom: 5rem;

    &__icon {
        --border-opacity: 1;
        border: 4px solid rgb(106 90 249/var(--border-opacity));
        width: 5rem;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1.25rem;

        svg {
            width: 1.75rem;
        }
    }

    &__title {
        max-width: 36rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2.5rem;
        line-height: 1.625;
        font-weight: 700;
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2.5rem;

        .btn {
            letter-spacing: .025em;
            font-weight: 600;
            width: 230px;
            padding-top: 1rem;
            padding-bottom: 1rem;
            padding-left: 2rem;
            padding-right: 2rem;

            svg {
                width: 24px;
                height: auto;
                margin-right: 0.75rem;
            }

            &.btn-github {
                --bg-opacity: 1;
                background-color: rgb(30 41 59/var(--bg-opacity));
            }
        }
    }

    &__social {
        -webkit-column-gap: 1.25rem;
        column-gap: 1.25rem;

        a {
            --bg-opacity: 1;
            --text-opacity: 1;
            background-color: rgb(51 65 85/var(--bg-opacity));
            color: rgb(255 255 255/var(--text-opacity));
            padding: 0.5rem;
            border-radius: 99px;

            &:hover {
                opacity: .7;
            }
        }
    }
}

@media (max-width:767px) {
    .section-banner {
        &__button {
            .btn {
                width: 200px;
                padding: 0.5rem;
            }
        }
    }
}

@media (max-width:375px) {
    .section-banner {
        &__button {
            .btn {
                width: 150px;
                padding: 0.5rem;

                span {
                    font-size: 0.75rem;
                }
            }
        }
    }
}